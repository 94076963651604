import parse from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { useSendForm } from '../hooks/send-from.hook'
import ModalC from './modal'
import { PhoneInput } from './phone_input'

export const Header = () => {
    const [title, setTitle] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const [actionOpen, setActionOpen] = useState(false)
    const [success, setSuccess] = useState(false)
    const sendForm = useSendForm()

    const getTitle = () =>{
        let paths = window.location.toString().split("?")[1]

        let utm={}
        if (paths==undefined){
            fetch(process.env.REACT_APP_BACKEND_URL+"/titles.php", {})
                .then(data => data.ok && data.json())
                .then(response => {
                    console.log(response.title)
                    setTitle(parse(response.title))
                });
        } else {
        if (paths.split('&').length>0){
            let params = paths.split('&')
            let metka = ""
            params.forEach((param)=>{
                param = param.split('=')
                if(param[0]=="utm_medium"){
                    console.log(param[1]);
					if (param[1]!=null){
                        if (metka !=""){
                            metka+="&"
                        }
                        metka+="utm_medium="+param[1]
					}
                }
                if(param[0]=="utm_source"){
                    console.log(param[1]);
					if (param[1]!=null){
                        if (param[1]!=null){
                            if (metka !=""){
                                metka+="&"
                            }
                            metka+="utm_source="+param[1]
                        }
					}
                }
            })
            if (metka!=""){
                fetch(process.env.REACT_APP_BACKEND_URL+"/titles.php?"+metka, {})
                                .then(data => data.ok && data.json())
                                .then(response => {
                                    console.log(response.title)
                                    setTitle(parse(response.title))
                                });
            }
        }}


        return utm
    }

    useEffect(()=>{
        //getTitle()
    },[])

    return (<React.Fragment>
            <section class="header plr">
                <div class="wmain">
                    <h1>
                        ЖК «Родной Дом»<br />Квартиры от 3 млн ₽
                    </h1>
                    <div class="head_title_dop">
                        15 минут до центра Краснодара
                    </div>
                    <a class="btn_main" href="#" onClick={(e)=>{e.preventDefault(); setIsOpen(true)}}>Заказать звонок</a>
                </div>
                {/*<div className={actionOpen?'action_ipoteka action_ipoteka__open':'action_ipoteka'}>*/}
                {/*    <div className='action_ipoteka__name' onClick={(e)=>{setActionOpen(!actionOpen)}}>*/}
                {/*        <span>*/}
                {/*            Ипотека 0%*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <div className='action_ipoteka__content'>*/}
                {/*        <div className='action_ipoteka__close' onClick={(e)=>{setActionOpen(false)}}>*/}
                {/*            <img src='img/action_ipot_close.png' />*/}
                {/*        </div>*/}
                {/*        <img className='action_ipoteka__img' src='img/action_ipoteka_img.jpg' />*/}
                {/*        <form className='action_ipoteka__form'>*/}
                {/*            <div className='aif__title'>Заполните заявку, и мы свяжемся с вами</div>*/}
                {/*            <div className='action_ipoteka__in'>*/}
                {/*                <input className="in_style" type="text" placeholder="Ваше имя" name="name" req="" />*/}
                {/*                <PhoneInput />*/}
                {/*                <input className="text" type="hidden" value="Ипотека 0%" />*/}
                {/*            </div>*/}
                {/*            <div className='align_center'>*/}
                {/*                <button class="btn_main" template={"1"} celtype={"getAction"} onClick={(e)=>{sendForm.sendForm(e, ()=>{setActionOpen(false)}); setSuccess(true)}}>Отправить</button>*/}
                {/*            </div>*/}
                {/*        </form>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </section>
            {isOpen?<ModalC
                fields={[
                    {
                        name: "name",
                        placeholder: "Ваше имя",
                        required: false,
                        type: "text",
                        class: "in_name",
                    },
                    {
                        name: "phone",
                        placeholder: "Ваш телефон",
                        required: true,
                        type: "text",
                        class: "in_phone",
                    },
                    {
                        name: "time",
                        placeholder: "Удобное время для звонка",
                        required: true,
                        type: "text",
                        class: "dop-info",
                    },
                ]}
                position={window.scrollY}
                hidden={`Заказать звонок`}
                btnTitle={'Заказать звонок'}
                title={"Закажите звонок"}
                celtype={"getCall"}
                template={2}
                personal={true}
                callback={()=>{console.log("success")}}
                close = {()=>{setIsOpen(false)}}
            />:""}
            {success?<ModalC
						success={success}
						position={window.scrollY}
						close = {()=>{setSuccess(false)}}
					/>:<div></div>}
        </React.Fragment>
    )
}

export default Header
