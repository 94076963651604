import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useSendForm } from "../hooks/send-from.hook";
import { Form } from "./form";

export const ModalC = ({title, position, hidden, flat, fields, celtype, btnTitle, success, personal, template, callback, close}) => {
  const sendForm = useSendForm()
  const [successShow, setSuccessShow] = useState(false)
  const successCallback = () => {
    setSuccessShow(true)
  }
  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo({
        top: position,
      });
    },0)
  },[])
  
  if (success || successShow) {
    return <Modal isOpen={true} toggle={() => {setModalState(null)}}>
      <ModalBody>
        <div key="ok" class="pu_rgba">
          <div class="pu_table">
            <div class="pu_cell">
              <div class="pu_inner pu_good" id="popup_ok" style={{display:"block"}}>
              <div class="closeform" onClick={(e)=>{e.preventDefault(); close()}}><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.8945 1.66618L2.34196 22.2188L0.894531 20.7713L21.4471 0.21875L22.8945 1.66618Z" fill="#AB0F14"/><path fill-rule="evenodd" clip-rule="evenodd" d="M2.34196 0.21875L22.8945 20.7713L21.4471 22.2188L0.894531 1.66618L2.34196 0.21875Z" fill="#AB0F14"/></svg></div>
                <div class="tm_main">
                  <div class="tm">
                    <span>Спасибо</span>
                    <div class="tm_table">
                      <div class="tm_cell">
                        <img data-src="img/text_spasibo.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pu_title_info">
                  В ближайшее время с вами свяжется наш специалист.
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  }

  if (flat) {
    const caseTitle = (classFlat) => {
      switch (classFlat) {
       case 0: return "Квартира-студия"
       case 1: return "Однокомнатная квартира"
       case 2: return "Двухкомнатная квартира"
       case 3: return "Трехкомнатная квартира"
      }
     }
    return  (
      
      <Modal isOpen={true} toggle={() => {setModalState(null)}}>
        <ModalBody>
        <div key="ok" class="pu_rgba">
          <div class="pu_table">
            <div class="pu_cell">
              <div class="pu_inner pu_flat_main">
                <div class="closeform" onClick={(e)=>{e.preventDefault(); close()}}>
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8945 1.66618L2.34196 22.2188L0.894531 20.7713L21.4471 0.21875L22.8945 1.66618Z" fill="#AB0F14"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.34196 0.21875L22.8945 20.7713L21.4471 22.2188L0.894531 1.66618L2.34196 0.21875Z" fill="#AB0F14"/>
                </svg>
                </div>
                <div class="tm">
                  {caseTitle(flat.rooms)}
                </div>
                <div class="pu_flat">
                  <div class="pu_flat__l">
                    <div class="pu_flat__info">
                      <span>Общая площадь <b>{flat.total_area} м<sup>2</sup></b></span>
                      <span>Жилая площадь <b>{flat.living_area} м<sup>2</sup></b></span>
                      {flat.bath_room_area>0?<span>Санузел <b>{flat.bath_room_area} м<sup>2</sup></b></span>:""}
                    </div>
                    <Form 
                    fields={fields}
                    hidden={hidden+". Кол-во комнат: "+flat.rooms+" Общая площадь: "+flat.total_area+" Жилая площадь: "+flat.living_area}
                    btnTitle={btnTitle}
                    celtype={celtype}
                    template={template}
                    callback={()=>successCallback()}
                    className={"pu_flat_form_l"}
                    personal={true}
                  />
                  </div>
                  <div class="pu_flat__r">
                    <img src={process.env.REACT_APP_PLANS_URL + flat.photo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </ModalBody> 
      </Modal>
    )
  }
  
  return (
    <Modal isOpen={true} toggle={() => {setModalState(null)}}>
      <ModalBody>
      <div style={{display: "block"}} class="pu_rgba plr">
        <div class="pu_table">
          <div class="pu_cell">
            <div class="pu_inner">
              <div class="closeform" onClick={(e)=>{e.preventDefault(); close()}}><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.8945 1.66618L2.34196 22.2188L0.894531 20.7713L21.4471 0.21875L22.8945 1.66618Z" fill="#AB0F14"/><path fill-rule="evenodd" clip-rule="evenodd" d="M2.34196 0.21875L22.8945 20.7713L21.4471 22.2188L0.894531 1.66618L2.34196 0.21875Z" fill="#AB0F14"/></svg></div>
              <div class="tm">{title}</div>
              <Form 
                  fields={fields}
                  hidden={hidden}
                  btnTitle={btnTitle}
                  celtype={celtype}
                  template={template}
                  callback={()=>successCallback()}
                  personal={true}
                />
            </div>
          </div>
        </div>
      </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalC