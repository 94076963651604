import { Swiper, SwiperSlide, } from 'swiper/react';
import { Lazy, Pagination, A11y } from 'swiper'
import React, { useEffect, useRef, useState } from 'react';

import 'swiper/css';
import 'swiper/css/pagination';
import ModalC from './modal';

export const PlansList = () => {
	const paginationRef = useRef()
	const [flats, setFlats] = useState([])
	const [type, setType] = useState("all")
	const [isOpen, setIsOpen] = useState(null)
	const [flatPopup, setFlatPopup] = useState(null)
	const [flatsToShow, setFlatsToShow] = useState(8)
	const [filterdFlats, setFiltredFlats] = useState([])
	const [hideMore, setHideMore] = useState(false)




	useEffect(() => {
		fetch(process.env.REACT_APP_BACKEND_URL + "/flats.php", {})
			.then(res => res.json())
			.then((result) => {
				let flats = []
				result.map((flat) => {
					let floors = []
					if (flat.floors != "") {
						let tmpFloor = flat.floors.split(',')
						tmpFloor.forEach((fl) => {
							floors.push(parseInt(fl))
						})
					}
					flat.floors = floors
					flats.push(flat)
				})
				setFlats(flats)
				setFiltredFlats(flats)

			})
	}, [])

	const moreClick = (e) => {
		e.preventDefault()
		setFlatsToShow(flatsToShow + 4)
	}

	useEffect(() => {
		
		
		if (type === 'all') {
			setFiltredFlats(flats)
			return
		}
		let newFiltredFlats = []
		flats.forEach((flat) => {
			if (type === flat.rooms) {
				newFiltredFlats.push(flat)
			}
		})
		setFiltredFlats(newFiltredFlats)
		
	}, [type])

	const flatClick = (e) => {
		e.preventDefault()
	}

	const kvTitle = (classKv) => {
		switch (classKv) {
			case 0:
				return "Квартира-студия"
			case 1:
				return "1 комнатная квартира"
			case 2:
				return "2 комнатная квартира"
			case 3:
				return "3 комнатная квартира"
		}
	}

	return (
		<React.Fragment>
			<section className="flat plr">
				<div className="wmain">
					<div className="tm">Планировки и цены квартир</div>
					<ul className="nav_list">
						<li><span className={type == "all" ? "act" : ""} onClick={() => {setType("all"); setFlatsToShow(8) }}>Все</span></li>
						<li><span className={type == 0 ? "act" : ""} onClick={() => {setType(0); setFlatsToShow(8) }}>Студии</span></li>
						<li><span className={type == 1 ? "act" : ""} onClick={() => {setType(1); setFlatsToShow(8) }}>1-комнатные</span></li>
						<li><span className={type == 2 ? "act" : ""} onClick={() => {setType(2); setFlatsToShow(8) }}>2-комнатные</span></li>
					</ul>
					<ul className='flat__list'>
						{filterdFlats.map((flat, index) => {
							if (index < flatsToShow) {
								return (<li onClick={(e) => { e.preventDefault(); setIsOpen(true); setFlatPopup(flat) }}>
									<div className="flat__item">
										<div className='flat__name'>{kvTitle(flat.rooms)}</div>
										<div className='flat__area'>ОБЩАЯ ПЛОЩАДЬ: <b>{flat.total_area}м²</b></div>
										<div className="flat_img"><img src={process.env.REACT_APP_PLANS_URL + flat.photo} /></div>
										<div className="btn_main" onClick={(e) => { e.preventDefault(); setIsOpen(true); setFlatPopup(flat) }}>Узнать стоимость</div>
									</div>
								</li>
								)
							}
						})}
					</ul>
					{filterdFlats.length > flatsToShow ?<div className="flat__list_all" onClick={moreClick}>
						<div>Показать еще</div>
					</div>:<></>}
				</div>
			</section>
			{isOpen ? <ModalC
				title={"Узнайте стоимость квартиры на сегодня"}
				position={window.scrollY}
				flat={flatPopup}
				fields={[
					{
						name: "name",
						placeholder: "Ваше имя",
						required: false,
						type: "text",
						class: "in_name",
					},
					{
						name: "phone",
						placeholder: "Ваш телефон",
						required: true,
						type: "text",
						class: "in_phone",
					},
				]}
				hidden={"Узнать стоимость"}
				btnTitle={"Узнать стоимость"}
				celtype={"getCost"}
				template={1}
				callback={() => { console.log("success") }}
				personal={true}
				close={() => { setIsOpen(false) }}
			/> : <div></div>}

		</React.Fragment>
	)
}

export default PlansList