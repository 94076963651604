import React, { useContext, useState } from 'react'
import { BlocksContext } from "../context/blocksContext"
import ModalC from './modal'
import parse from 'html-react-parser'
import { useSendForm } from '../hooks/send-from.hook'
import { PhoneInput } from './phone_input'

export const Menu = () => {
    const [isOpen, setModalState] = useState(null)
    const blocks = useContext(BlocksContext)

  const [setIsOpen] = useState(false)
  const [actionOpen, setActionOpen] = useState(false)

    const clickMenu = (e) => {
        e.preventDefault()
        let popup = e.currentTarget.getAttribute("href")
        if (blocks.blocks < 13) {
            blocks.setMenuClick(true)
            blocks.setBlocks(13)
            setTimeout(() => {
                window.scrollTo({
                    top: document.querySelector("." + popup).offsetTop,
                    behavior: "smooth"
                })
                setTimeout(() => {
                    blocks.setMenuClick(false)
                }, 500)


            }, 1200)

        } else {
            window.scrollTo({
                top: document.querySelector("." + popup).offsetTop,
                behavior: "smooth"
            })
        }
    }

    return (
        <React.Fragment>
            <div class="hl_top plr">
                <div class="wmain">
                    <div class="logo"><img src="img/logo.svg"  style={{width:"100px", height:"50px"}} /></div>
                    <div class="hl_right">
                        <div class="hl_nav">

                          <div className={actionOpen?'action_ipoteka action_ipoteka__open':'action_ipoteka'}>
                              <div className='' onClick={(e)=>{setActionOpen(!actionOpen)}}>

                              </div>
                              <div className='action_ipoteka__content'>
                                  <div className='action_ipoteka__close' onClick={(e)=>{setActionOpen(false)}}>
                                      <img src='img/action_ipot_close.png' />
                                  </div>
                                  <img className='action_ipoteka__img' src='img/action_ipoteka_img.jpg' />
                                  <form className='action_ipoteka__form'>
                                      <div className='aif__title'>Заполните заявку, и мы свяжемся с вами</div>
                                      <div className='action_ipoteka__in'>
                                          <input className="in_style" type="text" placeholder="Ваше имя" name="name" req="" />
                                          <PhoneInput />
                                          <input className="text" type="hidden" value="Ипотека 0%" />
                                      </div>
                                      <div className='align_center'>
                                          <button class="btn_main" template={"1"} celtype={"getAction"} onClick={(e)=>{sendForm.sendForm(e, ()=>{setActionOpen(false)}); setSuccess(true)}}>Отправить</button>
                                      </div>
                                  </form>
                              </div>
                          </div>
                            {/* <span onClick={(e)=>{setActionOpen(!actionOpen)}}>
                              Акции
                            </span> */}
                            <a onClick={clickMenu} href="infra">Инфраструктура</a>
                            <a onClick={clickMenu} href="flat">Планировки и цены</a>
                            <a onClick={clickMenu} href="buy">Способы покупки</a>
                            <a onClick={clickMenu} href="contact">Контакты</a>
                        </div>
                        <a class="hl_phone roistat-phone" href="tel:88612795353">8 861 279-53-53</a>
                        <div class="nav_mobile_ico">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen?<ModalC
                title={"Закажите звонок"}
                position={window.scrollY}
                fields={[
                    {
                      name: "name",
                      placeholder: "Ваше имя",
                      required: false,
                      type: "text",
                      class: "in_name",
                    },
                    {
                      name: "phone",
                      placeholder: "Ваш телефон",
                      required: true,
                      type: "text",
                      class: "in_phone",
                    },
                    {
                      name: "time",
                      placeholder: "Удобное время для звонка",
                      required: true,
                      type: "text",
                      class: "in_time dop-info",
                    },
                ]}
                hidden={"Заказать обратный звонок"}
                btnTitle={"Заказать звонок"}
                celtype={"getCall"}
                template={1}
                callback={()=>{console.log("success")}}
                personal={true}
                close = {()=>{setModalState(false)}}
            />:<div></div>}
        </React.Fragment>
    )
}
